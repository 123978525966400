import { graphql, Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import React from "react";
import ReactMarkdown from "react-markdown";
import Breadcrumbs from "../components/Breadcrumbs";
import ContactForm from "../components/Contactformulier";
import Layout from "../components/layout";
import RelatedCases from "../components/RelatedCases";
import SEO from "../components/seo";

export const ServiceItemCategoryPageTemplate = ({ post, breadcrumbs }) => {
  return (
    <div className="container mx-auto px-6  text-white flex flex-col  ">
      <div className="max-w-4xl mb-12">
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <p className="mt-6 prose lg:prose-2xl text-white">
          {post.lead_paragraph}
        </p>
        <ReactMarkdown
          className="prose lg:prose-lg text-white mt-6"
          source={
            post.description
              ? post.description.split(`](assets`).join(`](/assets`)
              : ""
          }
        />
      </div>

      <div className="mt-4 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-4">
        {post.service_items &&
          post.service_items.map((item) => {
            if (!item) {
              return undefined;
            }
            return (
              <div
                className="flex flex-col  items-center justify-center w-full"
                key={item.id}
              >
                <div className="flex flex-col  h-full">
                  <div className="flex flex-col flex-grow">
                    <div className="bg-gladior-dark w-full h-48 flex flex-col justify-center content-center items-center mb-3">
                      {item.background && (
                        <BackgroundImage
                          Tag="section"
                          fluid={item.background.childImageSharp.fluid}
                          style={{
                            backgroundBlendMode: "multiply",
                            backgroundPosition: "center",
                          }}
                          aria-label="Fullscreen Background"
                          className=" w-full h-48 flex flex-col justify-center content-center items-center mb-3"
                        >
                          <>
                            {item.thumbnail && (
                              <Img
                                alt={item.id}
                                fixed={item.thumbnail.childImageSharp.fixed}
                                title={item.id}
                              />
                            )}
                            <h3 className="text-lg text-white font-bold mb-3 font-display  text-center">
                              {item.id}
                            </h3>
                          </>
                        </BackgroundImage>
                      )}
                    </div>
                    <p className="text-base mb-2 font-light  ">
                      {item.caption}
                    </p>
                  </div>

                  <div className=" flex-grow-0 text-white flex flex-col justify-start content-start items-start text-center mt-2 mb-8 ">
                    <Link
                      className="bg-transparent hover:bg-gladior-pink  text-white  font-semibold hover:text-white py-2 px-4 border border-gladior-pink hover:border-transparent rounded"
                      to={item.fields.slug}
                    >
                      Meer informatie
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      {post.related_cases && post.related_cases.length > 0 && (
        <>
          <h3 className="mt-20 mb-10 text-2xl sm:text-3xl md:text-3xl xl:text-3xl leading-tight  text-white font-bold mb-3 font-display ">
            Our related cases
          </h3>
          <RelatedCases relatedCases={post.related_cases} />
        </>
      )}
    </div>
  );
};

const ServiceItemCategoryPage = ({ data }) => {
  const { serviceItemCategoryYaml: post, allServiceCategoryYaml } = data;

  let breadcrumbs = [];

  breadcrumbs.push({
    name: "Services",
    slug: "/services/",
  });

  if (allServiceCategoryYaml && allServiceCategoryYaml.edges.length > 0) {
    allServiceCategoryYaml.edges.forEach((cat) => {
      let services = cat.node.services;
      services.forEach((service) => {
        if (service.service_item_categories) {
          service.service_item_categories.forEach((category) => {
            if (category.id === post.id) {
              breadcrumbs.push({
                name: cat.node.id,
                slug: cat.node.fields.slug,
              });
              breadcrumbs.push({
                name: service.id,
                slug: service.fields.slug,
              });
              breadcrumbs.push({
                name: category.id,
              });
            }
          });
        }
      });
    });
  }

  return (
    <>
      <SEO
        description={post.seo.meta_description}
        pathname={post.path}
        title={post.seo.meta_title}
      />
      <Layout
        heroBackgroundImage={post.hero_background_image}
        title={post.title}
      >
        <main className="bg-gladior-dark ">
          <section className="container mx-auto px-6 py-10  max-w-7xl ">
            <ServiceItemCategoryPageTemplate
              post={post}
              breadcrumbs={breadcrumbs}
            />
          </section>
          <ContactForm />
        </main>
      </Layout>
    </>
  );
};

ServiceItemCategoryPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ServiceItemCategoryPage;

export const serviceItemCategoryPageQuery = graphql`
  query ServiceItemCategoryPage($id: String!) {
    allServiceCategoryYaml {
      # ) #   } #     } #       } #         service_item_categories: { elemMatch: { id: { eq: $id } } } #       elemMatch: { #     services: { #   filter: { # (
      edges {
        node {
          id
          fields {
            slug
          }
          services {
            fields {
              slug
            }
            id
            service_item_categories {
              id
              fields {
                slug
              }
            }
          }
        }
      }
    }

    serviceItemCategoryYaml(id: { eq: $id }) {
      id
      lead_paragraph
      seo {
        meta_description
        meta_title
      }
      hero_background_image {
        childImageSharp {
          fluid(quality: 90, maxWidth: 4160) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      related_cases {
        about_customer
        body
        description
        id
        lead_paragraph
        quote {
          organisation
          person
          quote
        }
        seo {
          meta_description
          meta_title
        }
        services
        subtitle
        templateKey
        title
        fields {
          slug
        }
        cover_image {
          childImageSharp {
            fixed(height: 256) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
      }
      title
      fields {
        slug
      }
      templateKey
      description
      service_items {
        id
        thumbnail {
          childImageSharp {
            fixed(height: 100) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        background {
          childImageSharp {
            fixed(height: 100) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        caption
        title
        fields {
          slug
        }
      }
    }
  }
`;
